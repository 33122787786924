<template>
    <section class="inner-section" :class="{'custom-type': customType}" v-background="backgroundOverlay">
        <base-container>
			<div class="product-wrapper">
				<figure class="product-image-wrapper">
					<cms-image
						:src="product.thumbnail"
						:base="{height: 300}"
						:sm="{height: 150}"
						:md="{height: 350}"
						:xl="{height: 550}"
						class="product-image"
					/>
					<cms-image
						:src="product.brand"
						:base="{width: 125}"
						:lg="{width: 130}"
						class="product-brand"
					/>
				</figure>
				<div class="product-details">
					<div class="product-buttons">
						<base-category-feature v-for="item in product.tags.traits" :key="item" :name="item" type="product"/>
						<base-category-feature v-for="item in product.tags.categories" :key="item" :name="item" type="product"/>
					</div>
					<ul class="list">
						<li class="list-item item" v-for="(item, index) in value.productData" :key="index">
							<base-font class="item-title">
								{{item.attributeDescription}}
							</base-font>
							<ul class="item-description" v-if="splitAttributeDescription(item.attributeName)">
								<base-font v-for="(element, id) of splitAttributeDescription(item.attributeName)"
										:key="id" tag="li" color="secondary" size="product-detail" class="item-content">
									{{ element }}
								</base-font>
							</ul>
							<base-font v-else color="secondary" size="product-detail" class="item-content" tag="span">{{item.attributeName}}</base-font>
						</li>
					</ul>
				</div>
			</div>
        </base-container>
    </section>
</template>
<script>
import BaseCategoryFeature from '~/website/front/components/molecules/BaseCategory/BaseCategoryFeature.vue'
export default {
	components: { BaseCategoryFeature },
	props: {
		value: Object
	},
	computed: {
		backgroundOverlay () {
			const path = '/products/bg-overlay.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		},
		type () {
			return this.page.value.section_type
		},
		customType () {
			return this.type !== 'base'
		},
		page () {
			return this.$app.page
		},
		product () {
			return this.page.product
		}
	},
	methods: {
		splitAttributeDescription (attributeDescription) {
			if (!attributeDescription) return ''
			if (attributeDescription.includes('\n')) return attributeDescription.split('\n')
			if (attributeDescription.includes('\r')) return attributeDescription.split('\r')
		}
	}
}
</script>
<style lang="scss" scoped>
.product-wrapper {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}

	.product-buttons {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			overflow: visible;
		}
	}
	.product-image-wrapper {
		display: flex;
		justify-content: center;
		position: relative;
		margin: 0;
		flex: 1 0 auto;
		::v-deep .product-image {

			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 50%;
				left: 5rem;
				transform: translateY(-50%);
			}
		}
		::v-deep .product-brand {
			position: absolute;
			bottom: .5rem;
			left: 0rem;
			@include media-breakpoint-up(lg) {
				bottom: 2rem;
				left: 0;

			}
		}
	}

	.product-details {
		padding-bottom: 2rem;
		@include media-breakpoint-up(lg) {
			margin-top: -1.5rem;
			padding-bottom: 4rem;
			width: 50%;
		}
	}
	.list {
		margin: 0;
		padding: 0;
		list-style: none;
		&-item {
			display: flex;
			align-items: center;
		}
		.item {
			@include media-breakpoint-up(xl) {
				grid-template-columns: 50% 50%;
			}
			display: grid;
			padding: 2rem 0;
			grid-template-columns: 100%;
			&:not(:last-child) {
				border-bottom: 1px solid rgba($gray-500, .2);
			}
			.item-description {
				padding: 0;
				list-style: none;
			}
		}
	}
}
.inner-section {
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 2rem;
	margin-bottom: 4rem;
	@include media-breakpoint-up(lg) {
		padding-top: 0;
		margin-bottom: 8rem;
	}
	&.custom-type {
		margin-bottom: 0;
		.product-wrapper {
			display: grid;
			gap: 1rem;
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(2, 1fr);
				gap: 3rem;
			}
		}
		.product-brand {
			left: 0;
			@include media-breakpoint-up(lg) {
				left: -3.5rem;
			}
		}
		::v-deep .product-image {
			.base-image {
				height: 100%;
				width: 100%;
				object-fit: contain;
			}
		}
		.product-details {
			width: 100%;
		}
	}
}
</style>
